<template>
  <div>
    <Header></Header>
    <div class="main">
      <div class="wp">
        <Navbar></Navbar>
        <div class="news-left pt-5 white-bg" style="float: unset;">
          <div class="left-top">
            <h3 class="news-title" style="color: #074d8c;">{{commonInfo.commonName}}</h3>
            <div class="top-info" style="border-bottom: 1px dashed #b0b0b0;"></div>
          </div>
          <div class="detail-contanier">
            <div class="detail-box" style="border: 0px;" v-html="commonInfo.commonInfo">
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
  import Header from "./index-header.vue"
  import Footer from "./index-footer.vue"
  import Navbar from "./navbar.vue"

  import { getCommonInfoByJsonpName } from "@/api/web/web.js";
  export default {
    name: "",
    data() {
      return {
        commonInfo : {}
      };
    },
    components: {
      Header,
      Navbar,
      Footer,
    },
    methods: {
      /**
       * 获取介绍信息
       */
      getCommonInfo(jsonpName) {
        getCommonInfoByJsonpName(jsonpName).then((res) => {
          if (res.status && res.data) {
            this.commonInfo = res.data
          }
        });
      }
    },
    mounted() {
      var query = this.$route.query;
      this.getCommonInfo(query.jsonp)
    },
  };
</script>

<style>
  @import '~@/assets/css/common.css';
  @import '~@/assets/css/index.css';
</style>
<style type="text/css" scoped>
  .detail-contanier p {
    text-indent: 2rem;
    margin-bottom: 0;
  }
  .detail-contanier {
    min-height: 350px;
  }
</style>
